import React from 'react'
import { getWidget } from '@utils/client'
import { ILayoutProps } from '../types.d'

export default function ({ config, path, reqCookie }: ILayoutProps) {
    // TODO: Use config.props.template
    return (
        <div className="grid">
            {config.children.map(({ id, props }, i) => {
                const Widget = getWidget(id)
                return (
                    <React.Fragment key={`${i}-${id}`}>
                        <Widget {...props} path={path} reqCookie={reqCookie} />
                    </React.Fragment>
                )
            })}
        </div>
    )
}
