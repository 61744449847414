import getAckoWidget from '../acko_modules/dynamic'
import Grid from '@components/Layout/Grid'
import Stack from '@components/Layout/Stack'
import { IDynamicComponentProps } from '@components/Layout/types'

export const getWidget: (id: string) => React.ComponentType<IDynamicComponentProps['props']> = id => {
    switch (id) {
        case 'Grid':
            return Grid
        case 'Stack':
            return Stack
        default:
            const widget = getAckoWidget(id)
            if (!widget) throw `WIDGET_NOT_FOUND: ${id}`
            return widget
    }
}
